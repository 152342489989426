import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/financial/Hero"
import SideBySide from "../components/contact/SideBySide"
import BottomContent from "../components/contact/BottomContent"

const conatct = props => {
  const hero = props?.data?.hero?.template?.contact
  const sideBySide = props?.data?.sideBySide?.template?.contact
  const bottomContent = props?.data?.bottomContent?.template?.contact

  return (
    <Layout>
      <Seo title="Contact" />
      <Hero data={hero} />
      <SideBySide data={sideBySide} />
      <BottomContent data={bottomContent} />
    </Layout>
  )
}

export const contactQuery = graphql`
  query contactTempPage($id: String!) {
    # seoInfo: wpPage(id: { eq: $id }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          templateName
          contact {
            heroImageTitle
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    sideBySide: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          contact {
            sbsContentTitleLeft
            sbsContentContentLeft
            sbsContentTitleRight
            sbsContentContentRight
          }
        }
      }
    }

    bottomContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          contact {
            bottomContentTitle
            bottomContentContent
            bottomContentIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default conatct
